import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
        <Layout fullMenu>
            <article id="main">
                <header className="major">
                    <h2>Impressum & Datenschutz</h2>
                    <p>
                        Verantwortlich: Timo Buhmann, Stuttgart, Deutschland
                        <br/>
                        E-Mail: webmaster&#160;<span className="icon solid fa-at">&#160;</span>butimo.info
                    </p>
                </header>
                <section className="wrapper style5">
                    <div className="inner">
                        <p>Stand: 20. Juli 2023</p>
                        <h3>Haftungsausschluss</h3>
                        <p>
                            Ich stelle die Inhalte dieser Internetseiten mit großer Sorgfalt zusammen und sorge für deren
                            regelmäßige Aktualisierung. Die Angaben dienen dennoch nur der unverbindlichen allgemeinen
                            Information und ersetzen nicht eine eingehende individuelle Beratung.
                        </p>
                        <p>
                            ich übernehme keine Gewähr für die Aktualität, Richtigkeit und Vollständigkeit der Informationen
                            auf diesen Seiten oder den jederzeitigen störungsfreien Zugang. Bei Verweisen (Links) auf
                            Internetseiten Dritter übernehme ich keine Verantwortung für die Inhalte der verlinkten Seiten.
                            Weiterhin schließe ich die Haftung bei Serviceleistungen, insbesondere beim Download von durch
                            mich zur Verfügung gestellten Dateien auf den Internetseiten dieser Domain, für leicht
                            fahrlässige Pflichtverletzungen aus.
                        </p>
                        <p>
                            Alle Rechte vorbehalten. Text, Bilder, Grafiken, Ton, Animationen und Videos sowie deren
                            Anordnung auf dieser Domain unterliegen dem Schutz des Urheberrechts und anderer Schutzgesetze.
                            Der Inhalt dieser Website darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert
                            oder Dritten zugänglich gemacht werden.
                        </p>
                        <p>
                            Das Bild <b>Ich als Sidemount Taucher</b> wird bereitgestellt von <b>Chris Godlewski</b> -
                            <a href="https://sidemountsociety.com" target="_blank">Sidemount Society</a> - und unterliegt
                            seinem Urheberrecht.
                        </p>
                        <p>
                            Das Bild <b>Person's Left Hand Holding Green Leaf Plant</b> wird bereitgestellt von <b>Alena
                            Koval</b> und unterliegt den
                            auf <a href="https://www.pexels.com/photo/person-s-left-hand-holding-green-leaf-plant-886521/"
                                   target="_blank">Pexel</a> verfügbaren Lizensbestimmungen.
                        </p>
                        <p>
                            Das Bild <b>grayscale photo of person scuba diving</b> wird bereitgestellt von <b>Talia
                            Cohen</b> und unterliegt den
                            auf <a href="https://unsplash.com/photos/AN2SypyyOnA"
                                   target="_blank">Unsplash</a> verfügbaren Lizensbestimmungen.
                        </p>
                        <p>
                            Diese Seite wurde mit <a href="https://www.gatsbyjs.com/" target="_blank">Gatsby</a> erstellt.
                            Danke an <a href="https://github.com/anubhavsrivastava" target="_blank">Anubhav
                            Srivastava</a> für die Bereitstellung
                            der <a href="https://www.gatsbyjs.com/starters/anubhavsrivastava/gatsby-starter-spectral"
                                   target="_blank">Spectral</a> Vorlage.
                        </p>
                        <h3>Datenschutz</h3>
                        <h4>Verantwortlicher</h4>
                        <p>
                            Timo Buhmann<br/>
                            Stuttgart, Deutschland<br/>
                            webmaster&#160;<span className="icon solid fa-at">&#160;</span>butimo.info
                        </p>
                        <h4>Maßgebliche Rechtsgrundlagen</h4>
                        <p>
                            <b>Maßgebliche Rechtsgrundlagen nach der DSGVO</b>: Im Folgenden erhalten Sie eine Übersicht der
                            Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen
                            Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem
                            bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere
                            Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
                            <ul>
                                <li><b>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</b> - Die Verarbeitung ist
                                    zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten
                                    erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der
                                    betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
                                </li>
                            </ul>
                        </p>
                        <p>
                            <b>Nationale Datenschutzregelungen in Deutschland</b>: Zusätzlich zu den Datenschutzregelungen
                            der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere
                            das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung
                            (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf
                            Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
                            personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie
                            automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Ferner können
                            Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
                        </p>
                        <p>
                            <b>Hinweis auf Geltung DSGVO und Schweizer DSG</b>: Diese Datenschutzhinweise dienen sowohl der
                            Informationserteilung nach dem schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG)
                            als auch nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie zu
                            beachten, dass aufgrund der breiteren räumlichen Anwendung und Verständlichkeit die Begriffe der
                            DSGVO verwendet werden. Insbesondere statt der im Schweizer DSG verwendeten Begriffe
                            „Bearbeitung“ von „Personendaten“ und "überwiegendes Interesse" werden die in der DSGVO
                            verwendeten Begriffe „Verarbeitung“ von „personenbezogenen Daten“ sowie "berechtigtes Interesse"
                            verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung des
                            Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
                        </p>
                        <h4>Übersicht der Verarbeitungen</h4>
                        <p>
                            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer
                            Verarbeitung zusammen und verweist auf die betroffenen Personen.
                        </p>
                        <p>
                            <b>Arten der verarbeiteten Daten</b>
                            <ul>
                                <li>Nutzungsdaten</li>
                                <li>Meta-, Kommunikations- und Verfahrensdaten</li>
                            </ul>
                            <b>Kategorien betroffener Personen</b>
                            <ul>
                                <li>Nutzer</li>
                            </ul>
                            <b>Zwecke der Verarbeitung</b>
                            <ul>
                                <li>Sicherheitsmaßnahmen</li>
                                <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit</li>
                                <li>Informationstechnische Infrastruktur</li>
                            </ul>
                        </p>
                        <h4>Sicherheitsmaßnahmen</h4>
                        <p>
                            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der
                            Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der
                            Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
                            Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und
                            organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
                        </p>
                        <p>
                            Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
                            Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten
                            als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der
                            Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
                            Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der
                            Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei
                            der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
                            Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
                        </p>
                        <p>
                            TLS-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen,
                            nutzen wir eine TLS-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem
                            Präfix https:// in der Adresszeile Ihres Browsers.
                        </p>
                        <h4>Bereitstellung des Onlineangebotes und Webhosting</h4>
                        <p>
                            Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu
                            können. Zu diesem Zweck verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die
                            Inhalte und Funktionen unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu
                            übermitteln.
                        </p>
                        <p>
                            <ul>
                                <li><b>Verarbeitete Datenarten</b>: Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
                                    Inhalten, Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. B.
                                    IP-Adressen, Zeitangaben, Identifikationsnummern, Einwilligungsstatus).
                                </li>
                                <li><b>Betroffene Personen</b>: Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                                </li>
                                <li><b>Zwecke der Verarbeitung</b>: Bereitstellung unseres Onlineangebotes und
                                    Nutzerfreundlichkeit; Informationstechnische Infrastruktur (Betrieb und Bereitstellung
                                    von Informationssystemen und technischen Geräten (Computer, Server etc.).);
                                    Sicherheitsmaßnahmen.
                                </li>
                                <li><b>Rechtsgrundlagen</b>: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</li>
                            </ul>
                            <b>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</b>
                            <ul>
                                <li><b>Erhebung von Zugriffsdaten und Logfiles</b>: Der Zugriff auf unser Onlineangebot wird
                                    in Form von so genannten "Server-Logfiles" protokolliert. Zu den Serverlogfiles können
                                    die Adresse und Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des
                                    Abrufs, übertragene Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp nebst
                                    Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
                                    Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können zum
                                    einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server
                                    zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
                                    DDoS-Attacken) und zum anderen, um die Auslastung der Server und ihre Stabilität
                                    sicherzustellen; Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
                                    DSGVO); Löschung von Daten: Logfile-Informationen werden für die Dauer von maximal 30
                                    Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren weitere
                                    Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
                                    jeweiligen Vorfalls von der Löschung ausgenommen.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Diese Datenschutzerklärung wurde mit der Hilfe des kostenlosen Datenschutz-Generators von <a
                            href="https://datenschutz-generator.de/" target="_blank">Dr. Thomas Schwenke</a> erstellt.
                        </p>
                    </div>
                </section>
            </article>
        </Layout>
    )
;

export default IndexPage;
